<template>
  <Form :model="archivesData" :inline="true" :label-colon="true" :disabled="true">
    <div style="width: 320px !important; display: inline-block">
      <FormItem label="档案编号" style="width: 100%">
        <Input
          :maxlength="20"
          v-model.trim="archivesData.archiveDetailVo.archiveNum"
          :disabled="true"
          style="width: 200px"
        ></Input>
      </FormItem>
    </div>
    <FormItem label="所属社区" style="width: calc(100% - 400px)">
       <Select v-model="archivesData.archiveDetailVo.orgCode"  style="width:200px">
        <Option v-for="(item,index) in orgCodeList" :data-label="item.label" :value="item.value" :key="index">{{ item.label }}</Option>
       </Select>
      <Checkbox
        :value="!archivesData.archiveDetailVo.orgCode"
        style="margin: 0 10px 0 20px"
        >所属其他</Checkbox
      >
      <Input
        style="width: 200px"
        :maxlength="20"
        v-model.trim="archivesData.archiveDetailVo.orgName"
        placeholder="请输入所属其他社区"
      ></Input>
    </FormItem>
    <Divider style="margin: 15px 0" />
    <div class="content">
      <h2 style="text-align: center">基本信息</h2>
      <FormItem label="人员类别" style="width: 100%">
        <RadioGroup v-model="archivesData.archiveDetailVo.difficultType">
          <Radio label="1">低保低收入对象</Radio>
          <Radio label="2">特困人员</Radio>
          <Radio label="3">留守老年人</Radio>
          <Radio label="4">独居孤寡老人</Radio>
          <Radio label="5">困境儿童</Radio>
          <Radio label="6">贫困重度残疾人</Radio>
          <Radio label="7">其他</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="建档社工" style="width: 24%">
        <Input
          :maxlength="20"
          v-model.trim="archivesData.archiveDetailVo.staffName"
          style="width: calc(100% - 100px)"
        ></Input>
      </FormItem>
      <FormItem label="建档时间" style="width: 24%">
        <DatePicker
        v-model="archivesData.archiveDetailVo.archiveCreate"
          type="date"
          placeholder="请选择出生日期"
          style="width: calc(100% - 100px)"
        ></DatePicker>
      </FormItem>
      <FormItem label="所属部门" style="width: 24%">
        <Select v-model="archivesData.archiveDetailVo.deptCode" style="width: calc(100% - 100px)">
          <Option
            v-for="(item,index) in deptCodeList"
            :value="item.value"
            :key="index"
            >{{ item.label }}</Option
          >
        </Select>
      </FormItem>

      <Divider style="margin: 15px 0" />

      <FormItem label="姓名" style="width: 24%">
        <Input
          :maxlength="20"
          v-model.trim="archivesData.archiveDetailVo.name"
          style="width: calc(100% - 100px)"
        ></Input>
      </FormItem>
      <FormItem label="性别" style="width: 24%">
        <RadioGroup v-model="archivesData.archiveDetailVo.sex">
          <Radio label="1">男</Radio>
          <Radio label="2">女</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="年龄" style="width: 24%">
        <p>{{ archivesData.archiveDetailVo.age }}</p>
      </FormItem>
      <FormItem label="职业" style="width: 24%">
        <Input
          :maxlength="20"
          v-model.trim="archivesData.userDetailVo.profession"
          style="width: calc(100% - 100px)"
        ></Input>
      </FormItem>
      <FormItem label="民族" style="width: 24%">
        <Select v-model="archivesData.userNeedyVo.national" style="width: calc(100% - 100px)">
          <Option
            v-for="(item,index) in nationList"
            :value="item.value"
            :key="index"
            >{{ item.label }}</Option
          >
        </Select>
      </FormItem>
      <FormItem label="联系电话" style="width: 24%">
        <Input
          :maxlength="20"
          v-model.trim="archivesData.archiveDetailVo.mobile"
          style="width: calc(100% - 100px)"
        ></Input>
      </FormItem>
      <FormItem label="身份证号码" style="width: 24%">
        <Input
          :maxlength="20"
          v-model.trim="archivesData.archiveDetailVo.idNum"
          style="width: calc(100% - 120px)"
        ></Input>
      </FormItem>
      <FormItem label="户籍" style="width: 24%">
        <Input
          :maxlength="20"
          v-model.trim="archivesData.userNeedyVo.hometown"
          style="width: calc(100% - 100px)"
        ></Input>
      </FormItem>
      <FormItem label="户籍地址" style="width: 100%">
        <Input
          :maxlength="20"
          v-model.trim="archivesData.userNeedyVo.idNumAddr"
          style="width: calc(100% - 150px)"
        ></Input>
      </FormItem>
      <FormItem label="现居住地址" style="width: 100%">
        <Input
          :maxlength="30"
          v-model.trim="archivesData.userDetailVo.addrCurrent"
          style="width: calc(100% - 155px)"
        ></Input>
      </FormItem>
      <FormItem label="监护人/紧急联系人" style="width: 24%">
        <Input
          :maxlength="20"
          v-model.trim="archivesData.userContactsVo.name"
          style="width: calc(100% - 150px)"
        ></Input>
      </FormItem>
      <FormItem label="紧急联系方式" style="width: 50%">
        <Input
          :maxlength="50"
          v-model.trim="archivesData.userContactsVo.mobile"
          style="width: calc(100% - 150px)"
          placeholder="多个手机号请用逗号隔开"
        ></Input>
      </FormItem>
      <FormItem label="人户分离情况" style="width: 100%">
        <RadioGroup v-model="archivesData.userDetailVo.registeredUser">
          <Radio label="1">户籍和人都在当地</Radio>
          <Radio label="2">人不在当地</Radio>
          <Radio label="3">户籍不在当地</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="教育程度" style="width: 100%">
        <RadioGroup v-model="archivesData.userDetailVo.eduDegree">
          <Radio label="0">未知</Radio>
          <Radio label="1">小学</Radio>
          <Radio label="2">初中</Radio>
          <Radio label="3">高中</Radio>
          <Radio label="4">专科</Radio>
          <Radio label="5">本科</Radio>
          <Radio label="6">硕士及以上</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="婚姻状况" style="width: 100%">
        <RadioGroup v-model="archivesData.userDetailVo.marital">
          <Radio label="0">未知</Radio>
          <Radio label="1">未婚</Radio>
          <Radio label="2">已婚</Radio>
          <Radio label="3">离异</Radio>
          <Radio label="4">丧偶</Radio>
          <Radio label="5">离异再婚</Radio>
          <Radio label="6">丧偶再婚</Radio>
           <Radio label="7">同居</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="居住状况" style="width: 100%">
        <RadioGroup v-model="archivesData.userHouseVo.liveType">
          <Radio label="1">独居</Radio>
          <Radio label="2">与亲人同居</Radio>
          <Radio label="3">与朋友同住</Radio>
          <Input
            v-if="archivesData.userHouseVo.liveType==2 ||archivesData.userHouseVo.liveType==3"
            :maxlength="30"
            v-model.trim="archivesData.userHouseVo.liveTypeDesc"
          ></Input>
        </RadioGroup>
      </FormItem>
      <FormItem label="居所类别" style="width: 100%">
        <RadioGroup v-model="archivesData.userHouseVo.houseType">
          <Radio label="1">自置物业</Radio>
          <Radio label="2">租房</Radio>
          <Radio label="3">宿舍</Radio>
          <Radio label="4">其他</Radio>
        </RadioGroup>
        <Input
          v-if="archivesData.userHouseVo.houseType == 4"
          :maxlength="20"
          v-model.trim="archivesData.userHouseVo.houseTypeDesc"
        ></Input>
      </FormItem>
      <FormItem label="身体健康情况" style="width: 100%">
        <RadioGroup v-model="archivesData.userHealthVo.healthStatus">
          <Radio label="1">健康良好</Radio>
          <Radio label="2">有疾病</Radio>
        </RadioGroup>
         <Input
          v-if="archivesData.userHealthVo.healthStatus == 2"
          :maxlength="30"
          v-model.trim="archivesData.userHealthVo.healthDesc"
        ></Input>
      </FormItem>
      <FormItem label="每月收入" style="width: 24%">
        <Input
          :maxlength="20"
          v-model.trim="archivesData.userIncomeVo.incomeMonthly"
          style="width: calc(100% - 100px)"
        ></Input>
      </FormItem>
      <FormItem label="家庭总收入" style="width: 24%">
        <Input
          :maxlength="20"
          v-model.trim="archivesData.userIncomeVo.incomeFamily"
          style="width: calc(100% - 100px)"
        ></Input>
      </FormItem>
      <FormItem label="救助服务使用情况" style="width: 100%">
        <Input
          :maxlength="400"
          v-model.trim="archivesData.helpServiceContent"
          type="textarea"
          show-word-limit
          :rows="3"
          style="width: calc(100% - 200px)"
        ></Input>
      </FormItem>
    </div>
  </Form>
</template>

<script>
export default {
  props: ["archivesData","orgCodeList","nationList"],
  data() {
    return {
      deptCodeList: []
    };
  },
  methods: {
     // 递归构建部门树
    getTreeFn(data,index) {
      return data.map(item => {
         return {
           value: item.code,
           label: item.title,
           children: (() => {
             if(item.children && item.children.length > 0 && item.children[0]) {
               return this.getTreeFn(item.children)
             }else {
               return [];
             }
           })()
         }
      })
    },
    //修改回显时查找部门树
    getdeptCode(list) {
      list.map(item => {
        if(JSON.stringify(item).indexOf(this.archivesData.addArchiveUserRo.deptCode[0]) != -1) {
          this.deptCodeList.push(item.value);
        if(item.children && item.children.length > 0 && item.children[0] && item.value != this.archivesData.addArchiveUserRo.deptCode[0]) {
          this.getdeptCode(item.children);
        }else {
           this.archivesData.addArchiveUserRo.deptCode = this.deptCodeList;
        }
        }
      })
    },
  },
   watch: {
    "archivesData.addArchiveUserRo.deptCode": {
      handler(newV) {
        if(this.deptCodeList != []) {
          this.getdeptCode(this.deptCodeList);
        }else {
          setTimeout(this.getdeptCode(this.deptCodeList),1000);
        }
      },
      immediate: true
    }
  },
  async created() {
     //获取所属部门
  await this.$get('/gx/pc/staff/selectUserPermissionDeptTree',{
       custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
      oemCode: parent.vue.oemInfo.oemCode,
      orgCode: parent.vue.loginInfo.userinfo.orgCode
    }).then(res => {
      if(res.code == 200 && res.dataList) {
        this.deptCodeList = this.getTreeFn(res.dataList);
      }else {
        this.$Message.error({
          background: true,
          content: res.desc,
        });
      }
    })
  },
};
</script>
    
<style scoped lang='less'>
.content {
  .ivu-form-item{
   width: 100%;
   margin-bottom: 15px;
    .ivu-input-wrapper {
      width: 200px;
      /deep/input {
        border: 0;
        border-bottom: 1px solid #65656c;
        border-radius: 0;
      }
      /deep/input:hover,/deep/input:focus {
           border: 0;
           border-bottom: 1px solid #65656c;
           box-shadow: none;
        }
        /deep/.ivu-input[disabled], fieldset[disabled] .ivu-input {
        background-color: #fff!important;
        color: #515a6e;
    }
    }
  }
}
 /deep/.ivu-input[disabled], fieldset[disabled] .ivu-input,/deep/.ivu-select-disabled .ivu-select-selection,/deep/.ivu-radio-disabled .ivu-radio-inner {
        background-color: #fff!important;
        color: #515a6e;
    }
     /deep/.ivu-radio-disabled .ivu-radio-inner,/deep/.ivu-checkbox-disabled.ivu-checkbox-checked .ivu-checkbox-inner {
      border: 1px solid #515a6e
    }
    /deep/.ivu-checkbox-disabled.ivu-checkbox-checked .ivu-checkbox-inner:after,/deep/.ivu-radio-disabled .ivu-radio-inner:after {
      border-color: #515a6e;
    }
    /deep/.ivu-radio-disabled .ivu-radio-inner:after {
      background-color: #888;
    }
</style>