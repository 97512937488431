<template>
  <div class="container">
    <!-- 左侧菜单 -->
    <Menu
      theme="light"
      :active-name="selectMenuName"
      @on-select="selectMenu"
      class="menu"
    >
      <MenuGroup title="菜单栏">
        <MenuItem name="ChangeInfo">基本信息</MenuItem>
        <MenuItem name="ChangeFamily">家庭成员</MenuItem>
        <MenuItem name="ChangeNeed">需求评估</MenuItem>
      </MenuGroup>
    </Menu>
    <div class="rightContent">
      <keep-alive>
        <component
          :is="componentName"
          :archivesData="archivesData"
          :orgCodeList="orgCodeList"
          :nationList="nationList"
        ></component>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import ChangeFamily from "@/views/archives/childrens/seeArchivesChildrens/archivesFamily";
import ChangeInfo from "@/views/archives/childrens/seeArchivesChildrens/archivesInfo";
import ChangeNeed from "@/views/archives/childrens/seeArchivesChildrens/archivesNeed";
export default {
  props: ['archivesData',"orgCodeList","nationList"],
  data() {
    return {
      selectMenuName: "ChangeInfo",
      componentName: "ChangeInfo",
    };
  },
 methods: {
      //选择菜单
  selectMenu(name) {
    this.componentName = name;
  },
 },
  components: {
    ChangeFamily,
    ChangeInfo,
    ChangeNeed,
  },
};
</script>
    
<style scoped lang='less'>
/deep/.ivu-menu-item-group-title {
  padding-left: 0 !important;
}
.menu {
  position: fixed;
  top: 15px;
  width: 210px !important;
  height: calc(100vh - 105px);
  z-index: 1;
}
.rightContent {
  margin-left: 240px;
  padding: 10px;
   height: calc(100vh - 105px);
   overflow: auto;
   padding-bottom: 80px;
}
</style>