<template>
  <div>
    <div style="display: flex;">
        <h2 style="margin-bottom:20px;flex:1;text-align:center">家庭成员信息</h2>
    </div>
    <Table
      border
      :columns="tableColumns"
      :data="archivesData.userMemberVos"
      max-height="calc(100vh - 200px)"
    ></Table>
  </div>
</template>

<script>
export default {
    props: ['archivesData'],
  data() {
    return {
      tableColumns: [
        {
          title: "成员姓名",
          key: "name",
        },
        {
          title: "成员与案主关系",
          key: "relationship",
        },
        {
          title: "成员年龄",
          key: "age",
        },
        {
          title: "成员工作单位",
          key: "workUnit",
        },
        {
          title: "成员联系电话",
          key: "mobile",
        },
      ],
    };
  },
};
</script>
    
<style scoped lang='less'>
</style>